<template>
  <div class="sum-work-hours">
    <h3 class="sum-work-hours__headline">Ergebnis</h3>

    <div class="sum-work-hours__container">
      <div class="valign-center a-center b-b">
        <div class="sum-work-hours__result">
          <p class="font--biggest m-b-1 m-t-0">{{ Number(hours).toFixed(2) }}</p>
          <p class="text-center m-0">Stunden</p>
        </div>

        <div class="sum-work-hours__result">
          <p class="font--biggest m-b-1 m-t-0">{{ Number(income).toFixed(2) }}</p>
          <p class="text-center m-0">Umsatz</p>
        </div>
      </div>

      <div class="text-center m-t-2">
        <p class="font--biggest m-b-1 m-t-0">{{ Number(average).toFixed(2) }}</p>
        <p class="m-0">Durchschnitt €/h</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SumWorkHours',

  props: {
    hours: {
      type: Number,
      required: true
    },

    income: {
      type: Number,
      required: true
    }
  },

  computed: {
    average() {
      let result = 0;

      if (this.hours && this.income) {
        result = this.income / this.hours;
      }

      return result;
    }
  }
};
</script>

<style lang="scss" scoped>
.sum-work-hours {
  &__headline {
    margin: 0;
    padding: 1rem 1rem 0.5rem;
    border-bottom: 1px solid $c-light-gray;
  }

  &__container {
    padding: 0 1rem 1rem;
  }

  &__result {
    width: 50%;
    text-align: center;
    padding-top: 2rem;
    padding-bottom: 1rem;
    height: 100%;

    &:first-child {
      border-right: 1px solid $c-light-gray;
    }
  }
}
</style>
